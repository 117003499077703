var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "form" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup",
          attrs: { title: "", active: _vm.popupSearchFroProjects },
          on: {
            "update:active": function ($event) {
              _vm.popupSearchFroProjects = $event
            },
          },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("Búsqueda de procesos"),
            ]),
            _c("div", { staticClass: "subtitle" }, [
              _vm._v(
                "\n        Elimina la opción al hacer clic en (X).\n      "
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "projectId" }, [
                _c("span", [_vm._v("Identificador del proceso")]),
              ]),
              _c(
                "div",
                { staticClass: "father" },
                [
                  _c("vs-input", {
                    staticClass: "input2",
                    attrs: {
                      type: "Number",
                      onkeydown: "return event.keyCode !== 69",
                      placeholder: "ID",
                    },
                    model: {
                      value: _vm.project.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "id", $$v)
                      },
                      expression: "project.id",
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "buttonReset",
                      attrs: { "position:static": "" },
                      on: {
                        click: function ($event) {
                          return _vm.resetId()
                        },
                      },
                    },
                    [_vm._v("\n            X\n          ")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "projectId1" }, [
                _c("span", [_vm._v("Fechas")]),
              ]),
              _c("div", { staticClass: "input" }, [
                _c(
                  "div",
                  { staticClass: "father" },
                  [
                    _c("datepicker", {
                      attrs: { placeholder: "Desde" },
                      model: {
                        value: _vm.project.start_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.project, "start_date", $$v)
                        },
                        expression: "project.start_date",
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "buttonReset1",
                        on: {
                          click: function ($event) {
                            return _vm.resetDateStart()
                          },
                        },
                      },
                      [_vm._v("\n              X\n            ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "input1" }, [
                _c(
                  "div",
                  { staticClass: "father" },
                  [
                    _c("datepicker", {
                      attrs: { placeholder: "Hasta" },
                      model: {
                        value: _vm.project.end_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.project, "end_date", $$v)
                        },
                        expression: "project.end_date",
                      },
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "buttonReset1",
                        on: {
                          click: function ($event) {
                            return _vm.resetDateEnd()
                          },
                        },
                      },
                      [_vm._v("\n              X\n            ")]
                    ),
                    _c("div", { staticClass: "message" }, [
                      _vm._v(_vm._s(_vm.message)),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "select1" }, [
                _c("span", [_vm._v("Ciudad")]),
              ]),
              _c(
                "div",
                { staticClass: "content-vSelect" },
                [
                  _c("vSelect", {
                    attrs: {
                      options: _vm.citiesJson,
                      reduce: function (city) {
                        return city.id
                      },
                      label: "name",
                      name: "projectType",
                      placeholder: "Seleccione una ciudad",
                    },
                    model: {
                      value: _vm.project.city_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "city_id", $$v)
                      },
                      expression: "project.city_id",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "select1" }, [
                _c("span", [_vm._v("Tipo de proceso")]),
              ]),
              _c(
                "div",
                { staticClass: "content-vSelect" },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.projectTypes,
                      reduce: function (state) {
                        return state.id
                      },
                      label: "name",
                      placeholder: "Seleccione el tipo de proceso",
                      name: "projectType",
                    },
                    model: {
                      value: _vm.project.project_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "project_type", $$v)
                      },
                      expression: "project.project_type",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "select1" }, [
                _c("span", [_vm._v("Estado")]),
              ]),
              _c(
                "div",
                { staticClass: "content-vSelect" },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.states,
                      label: "name",
                      reduce: function (state) {
                        return state.id
                      },
                      placeholder: "Seleccione el estado de la solicitud",
                      name: "projectType",
                    },
                    model: {
                      value: _vm.project.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "status", $$v)
                      },
                      expression: "project.status",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "select1" }, [
                _c("span", [_vm._v("Calificación")]),
              ]),
              _c(
                "div",
                { staticClass: "content-vSelect" },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.overalls,
                      label: "name",
                      reduce: function (overall) {
                        return overall.id
                      },
                      placeholder: "Seleccione una calificación",
                      name: "projectType",
                    },
                    model: {
                      value: _vm.project.overall_final,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "overall_final", $$v)
                      },
                      expression: "project.overall_final",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "projectId1" }, [
                _c("span", [_vm._v("Nombre aspirante")]),
              ]),
              _c(
                "div",
                { staticClass: "father" },
                [
                  _c("vs-input", {
                    staticClass: "input3",
                    attrs: { type: "text", placeholder: "Nombre" },
                    model: {
                      value: _vm.project.person_full_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "person_full_name", $$v)
                      },
                      expression: "project.person_full_name",
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "buttonReset",
                      on: {
                        click: function ($event) {
                          return _vm.resetName()
                        },
                      },
                    },
                    [_vm._v("\n            X\n          ")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "projectId1" }, [
                _c("span", [_vm._v("Documento")]),
              ]),
              _c(
                "div",
                { staticClass: "father" },
                [
                  _c("vs-input", {
                    staticClass: "input3",
                    attrs: { type: "text", placeholder: "Número de documento" },
                    model: {
                      value: _vm.project.person_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.project, "person_id", $$v)
                      },
                      expression: "project.person_id",
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "buttonReset",
                      on: {
                        click: function ($event) {
                          return _vm.resetCC()
                        },
                      },
                    },
                    [_vm._v("\n            X\n          ")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mb-6 ml-12" }, [
              _c("div", { staticClass: "projectId1" }, [
                _c("span", [_vm._v("Facturación")]),
              ]),
              _c("div", { staticClass: "input3" }, [
                _c(
                  "div",
                  { staticClass: "father" },
                  [
                    _c(
                      "vs-radio",
                      {
                        staticStyle: { "margin-left": "8px" },
                        attrs: { color: "#7d33ff", "vs-value": "true" },
                        model: {
                          value: _vm.project.invoiced,
                          callback: function ($$v) {
                            _vm.$set(_vm.project, "invoiced", $$v)
                          },
                          expression: "project.invoiced",
                        },
                      },
                      [_vm._v("Si\n            ")]
                    ),
                    _c(
                      "vs-radio",
                      {
                        staticClass: "radioButton",
                        attrs: { color: "#7d33ff", "vs-value": "false" },
                        model: {
                          value: _vm.project.invoiced,
                          callback: function ($$v) {
                            _vm.$set(_vm.project, "invoiced", $$v)
                          },
                          expression: "project.invoiced",
                        },
                      },
                      [_vm._v("No")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "buttonReset2",
                        on: {
                          click: function ($event) {
                            return _vm.radioReset()
                          },
                        },
                      },
                      [_vm._v("\n              X\n            ")]
                    ),
                  ],
                  1
                ),
                _vm.showLoader
                  ? _c(
                      "div",
                      { staticClass: "loader-create" },
                      [_c("Loader", { attrs: { show: "true" } })],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "buttonSearch",
                  on: {
                    click: function ($event) {
                      return _vm.getProjects()
                    },
                  },
                },
                [_vm._v("\n          Buscar\n        ")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }