var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: _vm.idName }, on: { click: _vm.generate } },
    [
      _vm._t("default", function () {
        return [_vm._v("\n\t\tDownload " + _vm._s(_vm.name) + "\n\t")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }