import api from './api'
export default {
  mixins:[api],
  data(){
    return {
      country_id:null,
      city_id:null,
      countries:[],
      cities:[]
    }
  },
  methods:{
    async getCountries(){
      try {
          let url = this.url() + 'api/v1/countries'
          let result = await this.requestGetWithoutContent(url,{})
          this.countries = result.data
          this.country_id = 1
          console.log(this.countries)
      } catch (error) {
        console.log(error)
      }
    },
    async getCities(){
      try {
        let url = this.url() + `api/v1/cities/?country_id=${this.country_id}`
        let result = await this.requestGetWithoutContent(url,{})
        this.cities = result.data
       } catch (error) {
         console.log(error)
       }
    },
  },
  watch:{
    country_id(){
      this.getCities()
    }
  }
}
