<template>
  <form class="form">
    <vs-popup class="popup" title="" :active.sync="popupSearchFroProjects">
      <div class="container">
        <div class="title">Búsqueda de procesos</div>
        <div class="subtitle">
          Elimina la opción al hacer clic en (X).
        </div>
        <div class="mb-6 ml-12">
          <div class="projectId">
            <span>Identificador del proceso</span>
          </div>
          <div class="father">
            <vs-input class="input2" type="Number" onkeydown="return event.keyCode !== 69" v-model="project.id"
              placeholder="ID" />
            <button position:static class="buttonReset" @click="resetId()">
              X
            </button>
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="projectId1">
            <span>Fechas</span>
          </div>
          <div class="input">
            <div class="father">
              <datepicker v-model="project.start_date" placeholder="Desde"></datepicker>
              <button class="buttonReset1" @click="resetDateStart()">
                X
              </button>
            </div>
          </div>
          <div class="input1">
            <div class="father">
              <datepicker v-model="project.end_date" placeholder="Hasta"></datepicker>
              <button class="buttonReset1" @click="resetDateEnd()">
                X
              </button>
              <div class="message">{{message}}</div>
            </div> 
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="select1">
            <span>Ciudad</span>
          </div>
          <div class="content-vSelect">
            <vSelect v-model="project.city_id" :options="citiesJson" :reduce="(city) => city.id" label="name"
              name="projectType" placeholder="Seleccione una ciudad"></vSelect>
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="select1">
            <span>Tipo de proceso</span>
          </div>
          <div class="content-vSelect">
            <v-select v-model="project.project_type" :options="projectTypes" :reduce="(state) => state.id" label="name"
              placeholder="Seleccione el tipo de proceso" name="projectType"></v-select>
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="select1">
            <span>Estado</span>
          </div>
          <div class="content-vSelect">
            <v-select v-model="project.status" :options="states" label="name" :reduce="(state) => state.id"
              placeholder="Seleccione el estado de la solicitud" name="projectType"></v-select>
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="select1">
            <span>Calificación</span>
          </div>
          <div class="content-vSelect">
            <v-select v-model="project.overall_final" :options="overalls" label="name" :reduce="(overall) => overall.id"
              placeholder="Seleccione una calificación" name="projectType">
            </v-select>
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="projectId1">
            <span>Nombre aspirante</span>
          </div>
          <div class="father">
            <vs-input class="input3" type="text" v-model="project.person_full_name" placeholder="Nombre" />
            <button class="buttonReset" @click="resetName()">
              X
            </button>
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="projectId1">
            <span>Documento</span>
          </div>
          <div class="father">
            <vs-input class="input3" type="text" v-model="project.person_id" placeholder="Número de documento" />
            <button class="buttonReset" @click="resetCC()">
              X
            </button>
          </div>
        </div>
        <div class="mb-6 ml-12">
          <div class="projectId1">
            <span>Facturación</span>
          </div>
          <div class="input3">
            <div class="father">
              <vs-radio color="#7d33ff" style="margin-left: 8px" v-model="project.invoiced" vs-value="true">Si
              </vs-radio>
              <vs-radio class="radioButton" color="#7d33ff" v-model="project.invoiced" vs-value="false">No</vs-radio>
              <button class="buttonReset2" @click="radioReset()">
                X
              </button>
            </div>
            <div class="loader-create" v-if="showLoader">
              <Loader show="true" />
            </div>
          </div>
        </div>
        <div>
          <button class="buttonSearch" @click="getProjects()">
            Buscar
          </button>
        </div>
      </div>
    </vs-popup>
  </form>
</template>

<script>
import mixinProjecs from "./Projects.js";
import Datepicker from "vuejs-datepicker";
import api from "../../../mixins/api";
import cities from "../../../mixins/cities.json";
import Loader from "../../../views/Loader.vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
export default {
  name: "FilterProjects",
  mixins: [mixinProjecs, api],
  props: ["popupSearchModal", "projects_pagination", "path"],
  computed: {
    ...mapGetters(["userInfo"]),
    popupSearchFroProjects: {
      get() {
        return this.popupSearchModal;
      },
      set(val) {
        this.$emit("update:popupSearchModal", val);
      },
    },

    projects: {
      get() {
        return this.$store.state.projects;
      },
      set(val) {
        this.$store.commit("changeProjects", val);
      },
    },
    ProjectsCount: {
      get() {
        return this.projects_pagination;
      },
      set(val) {
        this.$emit("update:projects_pagination", val);
      },
    },

    pathToFilter: {
      get() {
        return this.path;
      },
      set(val) {
        this.$emit("update:path", val);
      },
    },
    projectsExportFromProject: {
      get() {
        return this.projectsExport;
      },
      set(val) {
        this.$emit("update:projectsExport", val);
      },
    },
  },

  data: () => ({
    showLoader: false,
    project: {},
    params: "",
    message: '',
    citiesJson: cities.cities,
    searchCity: "",
    invoiced: false,
    overalls: [],
    projectTypes: [
      { id: 1, name: "Prueba psicotécnica" },
      { id: 2, name: "Estudio de seguridad" },
      { id: 3, name: "Listados de riesgo" },
      { id: 4, name: "Prueba de poligrafía" },
      { id: 5, name: "Antecedentes Judiciales" },
      { id: 6, name: "Visita Domiciliaria Basica" },
      { id: 7, name: "Academica y Antecedentes" },
      { id: 8, name: "Verificacion Academica" },
      { id: 9, name: "Referencias, Academicas y Laborales" },
      { id: 10, name: "Verificación Laboral" },
      { id: 11, name: "Referencias académicas y laborales" },
      { id: 12, name: "Visita OEA" },
      { id: 13, name: "Visita domiciliaria + Estudio de seguridad" },
      { id: 15, name: "Visita teletrabajo completo" },
      { id: 18, name: "Autodiagnóstico teletrabajo" },
      {
        id: 19,
        name: "Verificación de antecedentes y riesgo financiero",
      },
      {
        id: 20,
        name: "Verificación de antecedentes, referencias laborales y personales",
      },
    ],
    states: [
      { id: 1, name: "Sin asignar" },
      { id: 2, name: "Asignado" },
      { id: 4, name: "En progreso" },
      { id: 5, name: "Preliminar" },
      { id: 6, name: "Finalizado" },
    ],
  }),

  components: {
    vSelect,
    Loader,
    Datepicker,
  },
  created() {
    this.getOveralls();
  },

  watch: {
    searchCity(val) {
      console.log("searchClient -> ", val);
      var path = "/api/v1/cities/search?q[name_cont]=" + val;
      this.requestGetWithoutContent(this.url() + path, {}).then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.cities;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },

  methods: {
    change() {
      this.popupSearchFroProjects = false;
    },

    getOveralls() {
      this.requestGetWithoutContent(
        this.url() + "/api/v1/projects/get_overalls",
        {}
      )
        .then((response) => {
          if (response.status == 200) {
            let arrObjects = response.data.response;
            arrObjects.forEach((element) => {
              this.overalls.push({
                id: element[1],
                name: element[0],
              });
            });
          }
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
        });
    },

    getParams() {
      let user_id = this.userInfo.user.id;
      let params = "/api/v1/projects?user_id=" + user_id;

      if (this.project.id != null) {
        params += "&id=" + this.project.id;
      }
      if (this.project.start_date != undefined) {
        let start_date = this.project.start_date;
        params += "&start_date=" + start_date;
        if (this.project.end_date != undefined) {
          let end_date = this.project.end_date;
          params += "&end_date=" + end_date;
        } else {
          params +=
            "&end_date=" + new Date().toISOString().slice(0, 10);
        }
      }
      if (this.project.city_id != null) {
        params += "&city_id=" + this.project.city_id;
      }
      if (
        this.project.project_type &&
        this.project.project_type !== null
      ) {
        params += "&project_type=" + this.project.project_type;
      }

      if (this.project.status != null) {
        console.log(this.project.status);
        params += "&status=" + this.project.status;
      }

      if (this.project.overall_final != null) {
        console.log(this.project.overall_final);
        params += "&overall_final=" + this.project.overall_final;
      }
      if (this.project.person_full_name != null) {
        params += "&person_full_name=" + this.project.person_full_name;
      }

      if (this.project.person_id != null) {
        params += "&person_id=" + this.project.person_id;
      }

      if (this.project.invoiced != undefined) {
        params += "&invoiced=" + this.project.invoiced;
      }
      params += "&page=1&limit=20";
      return params;
    },

    async getProjects() {
      if (this.project.start_date > this.project.end_date) {
        return (this.message = 'Fecha no válida')
      }
      this.showLoader = true;
      let path = this.getParams();
      this.pathToFilter = path;
      this.message = ''

      this.requestGetWithoutContent(this.url() + path, {})

        .then((result) => {
          this.projects = result.data.projects;
          this.ProjectsCount = Math.ceil(
            result.data.projects_count / 20
          );
          this.popupSearchFroProjects = false;
          this.projectsExportFromProject = result.data.projects_count;
          this.showLoader = false;

        })
        .catch((err) => {
          console.log(err);
          this.showLoader = false;
        });
    },

    resetId() {
      this.project.id = "";
    },
    resetCity() {
      this.project.city_id = "";
    },
    resetDateStart() {
      this.project.start_date = "";
    },
    resetDateEnd() {
      this.project.end_date = "";
    },
    resetName() {
      this.project.person_full_name = "";
    },
    resetCC() {
      this.project.person_id = "";
    },
    radioReset() {
      this.project.invoiced = "";
    },
  },
};
</script>

<style>
.title {
  width: 693px;
  color: #5f0090;
  font-size: x-large;
  margin-top: 16px;
  margin-right: 520px;
  margin-bottom: 8px;
  font-weight: 900;
}

.subtitle {
  width: auto;
  color: rgb(24, 22, 23);
  margin-top: 4px;
  margin-left: 204px;
  margin-bottom: 16px;
  font-weight: 600;
}

.projectId {
  width: 150px;
  margin-left: 136px;
  margin-top: 42px;
  padding-left: 26px;
  font-weight: 800;
  color: #5f0090;
}

.projectId1 {
  margin-left: 136px;
  margin-top: 12px;
  padding-left: 26px;
  font-weight: 800;
  color: #5f0090;
}

.input {
  position: static;
  width: 210px;
  margin-left: 308px;
  margin-top: -20px;
}

.input1 {
  position: static;
  width: 210px;
  margin-left: 532px;
  margin-top: -38px;
}

.input2 {
  position: static;
  margin-left: 308px;
  margin-top: -40px;
  width: 434px;
}

.input3 {
  margin-left: 308px;
  margin-top: -20px;
  width: 434px;
}

.input5 {
  margin-left: 84px;
  margin-top: 12px;
  width: 434px;
}

.select1 {
  margin-left: 160px;
  margin-top: 12px;
  font-weight: 800;
  color: #5f0090;
}

.content-vSelect {
  margin-left: 308px;
  margin-top: -20px;
  max-width: 436px;
  color: #5f0090;
  border-color: #5f0090;
}

.buttonReset {
  position: absolute;
  color: #5f0090;
  width: 25px;
  margin-top: -27px;
  margin-left: 712px;
  background-color: white;
  font-size: 1rem;
  border: 2px solid #f7eff2;
  border-radius: 80%;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.buttonReset:hover {
  background-color: #F2EDFC;
}

.father {
  position: relative;
}

.buttonReset1 {
  position: absolute;
  color: #5f0090;
  width: 25px;
  margin-bottom: inherit;
  background-color: white;
  margin-top: -27px;
  margin-left: 180px;
  font-size: 1rem;
  border: 1px solid #f7eff2;
  border-radius: 50%;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.buttonReset1:hover {
  background-color: #F2EDFC;
}

.buttonReset2 {
  position: absolute;
  color: #5f0090;
  width: 25px;
  margin-bottom: inherit;
  margin-left: 16px;
  font-size: 1rem;
  border: 1px solid #f7eff2;
  border-radius: 50%;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.buttonReset2:hover {
  background-color: #F2EDFC;
  color: rgb(238, 216, 233);
}

.buttonSearch {
  margin-left: 290px;
  margin-top: 40px;
  background: #5f0090;
  border-radius: 4px;
  color: #f7eff2;
  width: 400px;
  border: 2px solid #5f0090;
  padding: 10px 32px;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.buttonSearch:hover {
  background-color: #7722a1;
  color: rgb(58, 56, 56);
}

.radioButton {
  margin-left: 30px;
}

.loader-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -146px;
  margin-top: 20px;
}

.message {
  margin-left: -60px;
  color: red;
}

@media (max-width: 1083px) {

  .subtitle {
    width: 280px;
  }

  .input2 {
    position: static;
    margin-left: 155px;
    margin-top: 12px;
    width: 334px;
  }

  .input {
    margin-left: 154px;
    margin-top: 12px;
  }

  .input1 {
    margin-left: 154px;
    margin-top: 12px;
  }

  .select1 {
    margin-bottom: 26px;
    width: 280px;
  }

  .content-vSelect {
    margin-left: 154px;
    max-width: 436px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .content-vSelect2 {
    margin-left: 154px;
    max-width: 434px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .content-vSelect3 {
    margin-left: 154px;
    max-width: 435px;
    width: 100%;
    transition: all 0.5s ease;
  }

  .input3 {
    margin-left: 154px;
    margin-top: 12px;
    width: 334px;
  }

  .buttonReset {
    position: absolute;
    color: #5f0090;
    width: 25px;
    margin-bottom: inherit;
    margin-top: -27px;
    margin-left: 450px;
    font-size: 1rem;
    border: 1px solid #f7eff2;
    border-radius: 50%;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }

  .input5 {
    margin-left: 146px;
    margin-top: 12px;
    width: 434px;
  }

  .buttonSearch {
    margin-left: 200px;
    width: 380px
  }

  .loader-create {
    margin-left: 56px;
  }

  .projectId {
    width: 300px;
  }

  .projectId1 {
    width: 300px;
  }

  .message{
  margin-left: 10px;
  }
}
</style>
