<template>
  <div class="vx-card project">
    <div id="popupsProject"></div>
    <vs-popup background-color="rgba(255,255,255,.6)"  class=""  title="Carga de archivos" :active.sync="popupUploadActive">
      <div class="files-candiate">
        <div class="list-documents">
          <vs-list>
            <vs-list-header icon="format_list_numbered" title="Documentos necesarios para iniciar el proceso"></vs-list-header>

            <ul>

              <div  v-for="(type, typeFileIndex) in typeFiles" :key="type.id">
                <div class="accordion-header" @click="toggleAccordion(typeFileIndex, false)">
                  <div class="item-document">
                    <div class="column">
                      <vs-button class="color-purple icon-add text-size" type="flat" :disabled="allowsNewFile(type.type_file)" @click.prevent="assignFile(type.type_file), toggleAccordion(typeFileIndex, true)" @click.stop>
                        <span class="material-icons item-icon">drive_folder_upload</span> {{ type.label }}
                      </vs-button>
                    </div>
                    <div class="column-2">
                      <span class="max-upload">Máximo {{ type.maxUploads }} {{ type.maxUploads == 1 ? 'archivo' : 'archivos'}}</span>
                    </div>
                    <vs-button class="icon-add" type="transparent" @click.prevent>
                      <span :id="`chevron${typeFileIndex}`" class="material-icons">expand_more</span>
                    </vs-button>
                  </div>

                  <div class="accordion-content" :id="`content${typeFileIndex}`">
                    <div  v-for="file in files" :key="file.input_id">
                        <span v-if="type.type_file === file.id">
                          <div v-if="file.content !== null" class="item-document">
                              <div>
                                <span class="material-icons item-icon color-green">check</span>
                                <span v-if="file.name_upload.length < 30" class="text-size color-green" @click.stop> {{ file.name_upload }}</span>
                                <span v-else class="text-size color-green" @click.stop> {{ file.name_upload.substring(0, 40) + '...' }}</span>
                              </div>
                              <vs-button class="item-icon" color="danger" type="flat" @click.prevent="clearInputsFile(file)" @click.stop><span class="material-icons item-icon">clear</span></vs-button>
                          </div>
                        </span>
                    </div>
                    <div v-if="noFilesAdded(typeFileIndex)" class="item-document">
                      <span class="no-added-files"> <span class="material-icons item-icon">info</span> No se han agregado archivos</span>
                    </div>
                  </div>
                </div>
              </div>

            </ul>
          </vs-list>
          <div v-for="file in files" :key="file.input_id">
            <input type="file" style="display: none;" :id="`inputFile${file.input_id}`" :ref="`inputFile${file.input_id}`" @change="preChargeFiles(file.input_id)" accept="image/png, image/jpg, image/jpeg, application/pdf"/>
          </div>
          <div v-show="this.non_valid_message" class="extension-error">
            <p  class="extension-message">Solo se permite subir formatos en png, jpg, jpeg y pdf.</p>
          </div>
          <div v-show="this.non_valid_size" class="extension-error">
            <p class="extension-message">El tamaño del archivo debe ser menor a 2 MB.</p>
          </div>

          <div class="loader-create" v-if="this.showLoader">
            <Loader show="true" />
          </div>

          <div class="upload-files-project">
            <vs-button color="success" type="border" :disabled="this.submitting" @click.prevent="uploadFilesProject()">Subir archivos</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
    <filter-projects :popupSearchModal.sync="popupSearch" :projects_pagination.sync="projects_count" :projectsExport.sync="projectsExport" :path.sync="path"/>
    <export-massive :popupSearchModal.sync="popupDialogMassive"/>
    <vs-popup background-color="rgba(255,255,255,.6)"  class=""  title="Carga masiva" :active.sync="popupUploadMasiveActive">
      <vs-row>
        <vs-col vs-offset="2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full">
                <vue-dropzone
                  ref="myVueDropzoneFiles"
                  id="dropzone"
                  class="dropzone"
                  @vdropzone-file-added="validateFileType"
                  :options="dropzoneOptions"
                  v-on:vdropzone-error="errorUpload"
                  v-on:vdropzone-success="successUpload"
                >
                </vue-dropzone>

                <div class="dropzone__error" v-if="dropzoneError !== ''" v-html="dropzoneError"></div>
              </div>
            </div>
        </vs-col>
      </vs-row>
    </vs-popup>
    <div class="vx-card__collapsible-content vs-con-loading__container">
      <div class="vx-card__body">
        <div class="btn-group">
          <vs-button color="success" v-bind:href="downloadTemplate()" target="_blank" v-if="authorizeCreatingContract">
            <span>
              Descargar plantilla excel
              <vs-icon icon="cloud_download"></vs-icon>
            </span>
          </vs-button>
          <vs-button color="primary" @click="uploadMasive()" v-if="authorizeCreatingContract">
            Carga Masiva
            <span>
              <vs-icon icon="cloud_upload"></vs-icon>
            </span>
          </vs-button>
          <vs-button color="primary" @click="openModalSearch()">
            Buscar
            <span>
              <vs-icon icon="search"></vs-icon>
            </span>
          </vs-button>
          <vs-button color="primary" @click="exporMassive">
            {{selectIdProjects == true ? 'Descarga masiva de reportes' : 'Seleccionar Procesos'}}
          </vs-button>
          <span v-if="selectIdProjects">
            {{projects_to_export.length}} / 5
          </span>
          <vs-button color="primary" @click="popupExport = true" v-if="projectsExport > 0">
            <span>
              Exportar Consulta
              <vs-icon icon="cloud_download"></vs-icon>
            </span>
            </vs-button>
          <vs-button @click="clearProject" color="primary" v-if="projectsExport > 0">
            <span>
              Limpiar Filtro
              <vs-icon icon="clear"></vs-icon>
            </span>
          </vs-button>

          <vs-popup class="holamundo"  title="Exportar Reporte a Excel" :active.sync="popupExport">
            <div>
              <vs-progress v-if="wait" indeterminate color="primary">primary</vs-progress>
              <h4>Cantidad de filas a exportar:{{this.projectsExport}} </h4>
              <vs-button color="primary" @click="exportReport">Exportar</vs-button>
              <json-to-excel style="display:none;" id="exportR" :data= "projectsToExcel" :fieldsExport="fieldsExcel" class="btn btn-default"> Download Data <img src="download_icon.png"></json-to-excel>
            </div>
          </vs-popup>


        </div>
        <br>
        <vs-progress indeterminate color="primary" v-if="progressReport"></vs-progress>
        <vs-table stripe max-items="20">
          <template slot="header">
            <h3>
              Listado de procesos
            </h3>
          </template>
          <template slot="thead">

            <vs-th sort-key="first_name">
              Número de proceso
            </vs-th>

            <vs-th sort-key="last_name">
              Empresa
            </vs-th>

            <vs-th sort-key="email">
              Fecha de creación
            </vs-th>

            <vs-th>
              Fecha de finalización
            </vs-th>

            <vs-th>
              Solicitó
            </vs-th>

            <vs-th>
              Centro de costo
            </vs-th>

            <vs-th>
              Nombre aspirante
            </vs-th>
            <vs-th>
              Cédula
            </vs-th>

            <vs-th>
              Proceso
            </vs-th>

            <vs-th>
              Estado
            </vs-th>

            <vs-th>
              Facturado
            </vs-th>

            <vs-th>
              Observaciones
            </vs-th>
            <vs-th>
              Calificación
            </vs-th>
            <vs-th>
              Ver reporte
            </vs-th>

            <vs-th>
              Descargar reporte
            </vs-th>

            <vs-th>
              Subir archivos
            </vs-th>

          </template>
          <template>
            <vs-tr :key="indextr" v-for="(tr, indextr) in projects" >

              <vs-td :data="projects[indextr].id">
                <span v-if="selectIdProjects">
                  <input type="checkbox" v-model="projects_to_export" :value="projects[indextr].id" name="" id="">
                </span>
                {{projects[indextr].id}}
              </vs-td>

              <vs-td :data="projects[indextr].client_name.slice(0, 20)">
                {{projects[indextr].client_name.slice(0, 20)}}
              </vs-td>

              <vs-td :data="projects[indextr].requested_at">
                {{projects[indextr].requested_at}}
              </vs-td>

              <vs-td :data="projects[indextr].delivered_at">
                {{projects[indextr].delivered_at}}
              </vs-td>

              <vs-td :data="projects[indextr].client_user_name">
                {{projects[indextr].client_user_name.slice(0, 20)}}
              </vs-td>

              <vs-td :data="projects[indextr].billing_center">
                {{projects[indextr].billing_center}}
              </vs-td>

              <vs-td :data="projects[indextr].person_full_name.slice(0, 20)">
                {{projects[indextr].person_full_name}}
              </vs-td>

              <vs-td :data="projects[indextr].person_id">
                {{projects[indextr].person_id}}
              </vs-td>

              <vs-td :data="projects[indextr].project_type_name">
                {{projects[indextr].project_type_name.slice(0, 20)}} {{projects[indextr].polygraph_type}}
              </vs-td>

              <vs-td :data="projects[indextr].status_name">
                {{projects[indextr].status_name.slice(0, 20)}}
              </vs-td>

              <vs-td :data="projects[indextr].invoiced">
                {{projects[indextr].invoiced}}
              </vs-td>

              <vs-td :data="projects[indextr].payment_observation">
                {{projects[indextr].payment_observation}}
              </vs-td>

              <vs-td :data="projects[indextr].overall_final_name">
                {{projects[indextr].overall_final_name}}
              </vs-td>
              <vs-td>
                <div class="btn-group">
                  <vs-button color="primary" @click="showProject(projects[indextr])">
                    <span>
                      <div class="point-view-project"></div>
                      <vs-icon icon="remove_red_eye"></vs-icon>
                    </span>
                  </vs-button>
                </div>
              </vs-td>

              <vs-td>
                <div class="btn-group">
                  <vs-button
                    color="warning"
                    target="_blank"
                    v-if="(projects[indextr].project_type_name) === 'Prueba de poligrafía' && (projects[indextr].polygraph_attachment).slice(0, 5) === '//s3.' "
                    :href="`https:${projects[indextr].polygraph_attachment}`">
                    <span>
                      <vs-icon icon="cloud_download"></vs-icon>
                    </span>
                  </vs-button>
                  <vs-button color="warning" v-else @click="linkToReport(projects[indextr].id,projects[indextr])" target="_blank">
                    <span>
                      <vs-icon icon="cloud_download"></vs-icon>
                    </span>
                  </vs-button>
                </div>
              </vs-td>

              <vs-td>
                <div class="btn-group">
                  <vs-button color="success" @click="uploadDocs(projects[indextr].id)">
                    <span>
                      <vs-icon icon="cloud_upload"></vs-icon>
                    </span>
                  </vs-button>
                </div>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <br>
        <vs-pagination :total="projects_count" v-model="currentx"></vs-pagination>
        <modal-message :modalActive="progressReport"/>
      </div>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import VueApexCharts from 'vue-apexcharts';
import vSelect from 'vue-select';
import api from '../../../mixins/api.js'
import cities from '../../../mixins/cities.js'
import Datepicker from 'vuejs-datepicker';
import StarRating from 'vue-star-rating'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ProjectComponent from '../../../components-globalwork/projects/Project'
import themeConfig from '@/../themeConfig.js';
const VxTour = () => import('@/components/VxTour.vue')
import Vue from 'vue'
import FilterProjects from './filter_component'
import { mapGetters, mapMutations } from 'vuex';
import mixinsProjects from './Projects.js';
import FilterProjectsMassive from './export_masive_pdf'
import validateFile from './../../../mixins/validateFile'
import JsonToExcel from './../../../components/JsonExcel.vue'
import ModalMessage from '../../../components-globalwork/ModalMessage'
import Loader from './../../../views/Loader.vue'

export default {
  mixins: [api, cities,mixinsProjects,validateFile],
  data(){
    /** Icono de Excel, se utiliza en el dropzone de la carga masiva... */
    let xlsURL = require('@/assets/images/xls.png')

    return{
      selectIdProjects:false,
      progressReport: false,
      authorizeCreatingContract: true,
      params: '',
      projectsExport: 0,
      messageSendLink: '',
      projects_count: 0,
      currentx: 1,
      dropzoneError: '',
      dropzoneOptions: {
        url: '',
        thumbnailWidth: 150,
        maxFilesize: 10,
        headers: {
          'client': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm1, this.url()).toString(this.CryptoJS.enc.Utf8),
          'access-token': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm0, this.url()).toString(this.CryptoJS.enc.Utf8),
          'uid': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm2, this.url()).toString(this.CryptoJS.enc.Utf8),
          'token': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm0, this.url()).toString(this.CryptoJS.enc.Utf8)
        },
        dictDefaultMessage: `<img src="${ xlsURL }"> Selecciona o arrastra tu archivo .xlsx aquí`
      },
      dropzoneOptionsMassive: {
        url: '',
        thumbnailWidth: 150,
        maxFilesize: 10,
        headers: {
          'client': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm1, this.url()).toString(this.CryptoJS.enc.Utf8),
          'access-token': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm0, this.url()).toString(this.CryptoJS.enc.Utf8),
          'uid': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm2, this.url()).toString(this.CryptoJS.enc.Utf8),
          'token': this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm0, this.url()).toString(this.CryptoJS.enc.Utf8)
        },
        dictDefaultMessage: 'Arrastrar y soltar los archivos'
      },
      colorx:"rgba(255,255,255,255)",
      cities_proccess: [],
      popupActive: false,
      popupShowActive: false,
      popupUploadActive: false,
      popupUploadMasiveActive: false,
      urlUploadDocs: '',
      urlUploadMasive: '',
      project: {},
      projectView: {},
      projectShow: {},
      project_id: '',
      create: true,
      city_find: '',
      id: '',
      start_date: '',
      end_date: '',
      project_type: null,
      status: '',
      person_full_name: '',
      person_id: '',
      invoiced: false,
      project_id_create: null,
      fieldsExcel: {
               'Número de proceso': 'id',
               'Empresa': 'client_name',
               'Fecha de creación': 'requested_at',
               'Tiempo restante': 'left_time',
               'Fecha de finalización': 'delivered_at',
               'Solicitó': 'client_user_name',
               'Centro de costo': 'billing_center',
               'Nombre aspirante': 'person_full_name',
               'Cédula': 'person_id',
               'Ciudad': 'city_name',
               'Cargo': 'current_activity',
               'Proceso': 'project_type_name',
               'Estado': 'status_name',
               'Calificación': 'overall_final_name',
               'Facturado': 'invoiced',
               'Observaciones': 'payment_observation',
              },
      object_icons: {
        "fa fa-smile-o": "icon-check-circle",
        "fa fa-clock-o": "icon-loader",
        "fa fa-tasks": "icon-activity"
      },
      disableThemeTour: themeConfig.disableThemeTour,
      path:'/api/v1/projects?',
      popupExport:false,
      wait:false,
      non_valid_message: false,
      non_valid_size: false,
      showLoader: false,
      submitting: true,
      projectsToExcel:[],
      popupDialogMassive:false,
      inputFile:'',
      loading:false,
      projects_to_export:[],
      typeFiles:[
        {
          type_file: 0, name:'Hoja de vida', label: 'Subir Hoja de Vida *', maxUploads: 1
        },
        {
          type_file: 1, name: 'Habeas Data', label: 'Subir Habeas Data *', maxUploads: 1
        },
        {
          type_file: 2, name:'Diploma Academico', label: 'Subir Diploma Académico', maxUploads: 4
        },
        {
          type_file: 3, name: 'Certificado Academico', label: 'Subir Certificado Académico', maxUploads: 4
        },
        {
          type_file: 4, name: 'Certificado Laboral', label: 'Subir Certificado Laboral', maxUploads: 4
        }
      ],
      type_file:null,
      files: [],
      filesTemplate: [
        {
          id: 0, input_id: 0, name_archive: 'Hoja de vida', name:'Subir Hoja de vida *', name_upload: 'Hoja de vida cargada', content: null
        },
        {
          id: 1, input_id: 1, name_archive: 'Habeas data', name:'Subir Habeas Data *', name_upload: 'Habeas data cargado', content: null
        },
        {
          id: 2, input_id: 2, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 2, input_id: 3, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 2, input_id: 4, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 2, input_id: 5, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 3, input_id: 6, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 3, input_id: 7, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 3, input_id: 8, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 3, input_id: 9, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 4, input_id: 10, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        },
        {
          id: 4, input_id: 11, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        },
        {
          id: 4, input_id: 12, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        },
        {
          id: 4, input_id: 13, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        }
      ]
    }
  },

  computed:{
    ...mapGetters(['userInfo']),

    projects:{
      get(){
        return this.$store.state.projects
      },
      set(val){
        this.$store.commit('changeProjects',val)
      }
    }
  },
  methods:{
    ...mapMutations(['changeCurrentProject']),
    iconItemDocument(file) {
      if (file.content == null) {
        return 'drive_folder_upload'
      } else {
        return 'check'
      }
    },
    nameItem(file) {
      if (file.content == null) {
        return file.name
      } else {
        return file.name_upload
      }
    },
    colorButtonUpload(file) {
      if (file.content == null) {
        return 'primary'
      } else {
        return 'success'
      }
    },
    onPickFile(typeFileId) {
      const ref = `inputFile${typeFileId}`
      this.$refs[ref][0].click()
    },
    async preChargeFiles(typeFileId) {
      const ref = `inputFile${typeFileId}`
      const file = this.$refs[ref][0].files[0]
      const valid_file = this.checkFile(file.name)
      if (file.size < 2000000) {
        if (valid_file) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const result = await new Promise((resolve) => {
            reader.onload = function() {
              resolve(reader.result)
            };
          })
          this.setContentFile(typeFileId, result, file.name)
        } else {
          this.clearInputsFile(this.files[typeFileId])
        }
      } else {
          this.non_valid_size = true
          setTimeout(() => this.non_valid_size = false, 5000)
          return false
      }
      this.disabledSubmit()
    },
    setContentFile(typeFileId, content, name) {
      this.files.forEach(element => {
        if (element.input_id == typeFileId) {
          element.content = content
          element.name_upload = ''
          element.name_upload = `${this.filesTemplate.filter((fileTemplate) => fileTemplate.input_id == typeFileId)[0].name_archive} (${name})`
        }
      });
    },
    clearInputsFiles() {
      this.files.map((file) => this.clearInputsFile(file));
    },
    clearInputsFile (file) {
      this.files.forEach(element => {
        if (element.input_id == file.input_id) {
          element.content = null
        }
      });
      this.disabledSubmit()
      let ref = `#inputFile${file.input_id}`
      document.querySelector(ref).value = null
    },
    async uploadFilesProject () {
      this.submitting = true
      this.showLoader = true
      this.requestPost(`${this.url()}/api/v1/projects/import_files`, {project_id: this.project_id, files: this.files}).then(request => {
        if (request.status == 200) {
          this.$vs.notify({
            title: 'Mensaje de Globalwork',
            text: request.data.message,
            color: 'primary',
            position: 'bottom-left'
          })
        }
        this.showLoader = false
        this.setDefaultFiles()
        this.resetChevrons()
        this.resetAccordions()
        this.clearInputsFiles()
        this.disabledSubmit()
        this.submitting = false
        this.popupUploadActive = false
      }, (err) => {
          this.showLoader = false
          this.setDefaultFiles()
          this.resetChevrons()
          this.resetAccordions()
          this.clearInputsFiles()
          this.disabledSubmit()
          this.submitting = false
          this.popupUploadActive = false

          this.$vs.notify({
          title: 'Error del servidor',
          text: 'No se ha podido adjuntar. Por favor intenta nuevamente.',
          color: 'danger',
          position: 'bottom-left'
        })
      })
    },
    assignFile(type_file){
      let index = this.files.findIndex((file) => file.id === type_file && file.content == null)
      let file = this.files[index]
      this.resetChevrons()
      this.resetAccordions()
      this.onPickFile(file.input_id)
    },
    allowsNewFile(type_file){
      const typeFile = this.typeFiles.findIndex(tp => tp.type_file === type_file)
      const maxUploads = this.typeFiles[typeFile].maxUploads
      let filesUploaded = this.files.filter((file) => file.id === type_file && file.content !== null);
      return filesUploaded.length >= maxUploads
    },
    checkFile(content) {
        const fileElement = content
        let fileExtension = "";
        if (fileElement.lastIndexOf(".") > 0) {
            fileExtension = fileElement.substring(fileElement.lastIndexOf(".") + 1, fileElement.length);
        }
        if (['pdf', 'png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase())) {
            return true
        }
        else {
            this.non_valid_message = true
            setTimeout(() => this.non_valid_message = false, 5000)
            return false
        }
    },
    getContractsActive(){
      var path = "/api/v1/clients/"+this.clientId+"/contracts_active"
      var url = this.url()+path
      this.requestGetWithoutContent(url, {}).then(response => {
        if (response.status == 200){
          var contracts = response.data
          if (contracts.length > 0){
            this.authorizeCreatingContract = true
          }
        }else{
          console.log('No se pudo obtener los contratos ')
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })
    },
    setHired(project_id, index){
      var path = "api/v1/projects/set_hired"
      var projectNew = this.projects[index]
      this.requestPut(this.url()+path, {id: project_id, hired: true}).then(response => {
        console.log(response)
        if(response.status == 200){
          projectNew.hired = response.data.hired
          Vue.set(this.projects, index, projectNew)
        }else{
          console.log("error");
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })
    },
    loadProjectsExports(all){
      var path = "/api/v1/projects/list_all_projects"+this.params+"&all="+all;
      console.log("--> this.url()+path -> ", this.url()+path)
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.data.success == true){
          this.projectsExport = response.data.projects
          if(this.projectsExport.length == 0){
            this.projectsExport = this.projects
          }
        }else{
          console.log("error -> " + response.data.message);
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })
    },
    downloadTemplate(){
      return "https://globalwork-production.s3.amazonaws.com/Pdf/FormatoMasivosGlobalworkV6.xlsx"
    },
    async linkToReport(projectId,project){
      this.loading = true
      let fileName = project.person_full_name + " - " + project.project_type_name + " - " + project.person_id
      await this.linkReport(projectId,this.userInfo.user.id,fileName)
      this.loading = false
    },
    showForm(){
      this.closePopups('popupActive')
      this.popupActive = true;
      this.resetFormProject();
    },
    closePopups(popupShow){
       if ('popupActive' != popupShow){
         this.popupActive = false
       }
       if ('popupShowActive' != popupShow){
         this.popupShowActive = false
       }
       if ('popupUploadActive' != popupShow){
         this.popupUploadActive = false
       }
       if ('popupSearch' != popupShow){
         this.popupSearch = false
       }
       if ('popupUploadMasiveActive' != popupShow){
         this.popupUploadMasiveActive = false
       }
    },
    editProject(project){
      this.project = project;
      this.create = false;
    },
    resetFormProject(){
      this.project = {}
      this.create = true;
    },
    getProjects(){
      if(!this.path.includes("user_id")){
        let user_id = this.userInfo.user.id
        this.path += "user_id="+user_id+"&page="+this.currentx+"&limit="+20
      }else{
        this.path = this.path.split("&page")[0] += "&page="+this.currentx+"&limit=20"
      }
      this.requestGetWithoutContent(this.url()+this.path, {}).then(response => {
        if(response.data.success == true){
          this.projects = response.data.projects
          this.projects_count = Math.ceil(response.data.projects_count/20)
        }else{
          console.log("error -> " + response.data.message);
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })
    },
    getProject(project_id){
      var user_id = this.userInfo.user.id
      var path = "/api/v1/projects/" + project_id + "?user_id="+user_id;
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.data.success == true){
          this.projectShow = response.data.data
          this.popupShowActive = true;
          var projectComponent = Vue.extend(ProjectComponent)
          var instanceProjectComponent = new projectComponent({
            propsData: {project: this.projectShow, popupShowActive: this.popupShowActive}
          })
          instanceProjectComponent.$mount()
          var popup = document.getElementById('popupsProject')
          popup.appendChild(instanceProjectComponent.$el)
        }
      }, (err) => {
        console.log('err users -> ' +  JSON.stringify(err))
      })
    },
    showProject(project){
      this.changeCurrentProject(project)
      this.projectView = project;
      this.popupUploadActive = false;
      this.getProject(project.id)
    },
    async uploadDocs(project_id){
      this.userInfo.user.id
      this.dropzoneOptionsMassive.headers = await this.headers()
      this.popupShowActive = false;
      this.popupUploadActive = true;
      var path = "api/v1/project_archives?project_id=" + project_id
      this.urlUploadDocs = this.url() + path;
      this.dropzoneOptionsMassive.url = this.urlUploadDocs
      this.project_id = project_id
    },
    sendLinkCandidate(project_id){
      // var user_id = this.userInfo.user.id
      var path = "api/v1/clients/send_link_candidate?project_id=" + project_id
      this.requestGetWithoutContent(this.url()+path, {}).then(response => {
        if(response.data.success == true){
          var person_full_name = response.data.data.person_full_name
          this.messageSendLink = "Link enviado al candidato " + person_full_name
          this.$vs.notify({color:'success',title:'Link enviado',text: this.messageSendLink, position: 'bottom-left'})
        }else{
          this.messageSendLink = "No se pudo enviar el link, por favor comuníquese con soporte"
          this.$vs.notify({color:'warning',title:'Link enviado',text: this.messageSendLink, position: 'bottom-left'})
        }
      }, (err) => {
        this.messageSendLink = "Error intentando conectar al servidor, por favor comuníquese con soporte. " +  err
        this.$vs.notify({color:'warning',title:'Link enviado',text: this.messageSendLink, position: 'bottom-left'})
      })
    },
    uploadMasive(){
      this.closePopups('popupUploadMasiveActive')
      this.popupUploadMasiveActive = true;
      var user_id = this.userInfo.user.id
      var path = "/api/v1/projects/import?current_user=" + user_id+"&created_at_front=" + new Date().toLocaleString();
      this.urlUploadMasive = this.url() + path;
      this.dropzoneOptions.url = this.urlUploadMasive
      this.dropzoneOptions.headers.token = this.userInfo.token
    },


    /**
     * Error hanlder para el componente dropzone...
     */
    errorUpload(file, message) {
        console.log('ERROR: ', message);
        this.dropzoneError = 'Parece que tenemos un error en estos momentos, por favor, inténtalo más tarde.'
        this.$refs.myVueDropzoneFiles.removeAllFiles()
    },

    /**
     * Success hanlder para el componente dropzone...
     */
    successUpload(file, message){
        console.log('SUCCESS: ', message)

        if (message.success === true) {
              this.getProjects()
              this.popupUploadMasiveActive = false;
              this.$vs.notify({color:'success',title:'Información importada',text:'Los procesos fueron creados con éxito', position: 'bottom-left'})
              this.$refs.myVueDropzoneFiles.removeAllFiles()
        } else {
            let rows = this.concatenateErrorRows(message.data.errors)
            this.dropzoneError = `No pudimos crear tus procesos, comprueba que todos los datos de la <strong>${ rows }</strong>. Por favor, verifique que todos los datos estén completos y que no se haya ingresado un número de documento duplicado.`
        }

        this.$refs.myVueDropzoneFiles.removeAllFiles()
    },
    /**
     * Concatena las filas con 'coma' o 'y' donde hubo error en la
     * carga masiva de los procesos...
     */
     concatenateErrorRows(rows) {
        if (rows.length === 0) {
          return ''
        } else if (rows.length === 1) {
          return `• ${rows[0]}`
        } else {
          const bulletPoints = rows.map((row) => `• ${row}`)
          const lastRow = bulletPoints.splice(-1)[0]
          return bulletPoints.join(' \n') + ' \n y ' + lastRow
        }
    },

    errorsUpload(event){
      console.log(event)
    },
    openModalSearch(){
      this.popupSearch = true
      this.closePopups('popupSearch')
    },
    exporMassive(){
      if (this.selectIdProjects){
        this.progressReport = true
        let path = this.urlPdfGenerator()+'massive?projects=['+this.projects_to_export+']'
        this.downloadBase64File(path,'','reportes','.zip')
        this.selectIdProjects = false
        this.projects_to_export = []
      }else{
        this.selectIdProjects = true
      }
    },
    exportReport() {
      this.wait = true
      let pathToExcelData = this.path.split("&limit=20")[0] += "&limit="+this.projectsExport
      this.requestGetWithoutContent(this.url() + pathToExcelData,{}).then(res=>{
        this.projectsToExcel = res.data.projects
        setTimeout(()=>{
          document.getElementById("exportR").click()
        },500)
        this.wait = false
      })
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then(result => {
          if (result) {
            resolve(true)
          } else {
            reject("correct all values");
          }
        })
      })
    },
    validateStep3() {
      var user_id = this.userInfo.user.id
      this.project_id_create = -1
      this.popupUploadActive = true;
      var path = "api/v1/projects/multiple_uploads?project_id=" + this.project_id_create + "&current_user=" + user_id;
      this.urlUploadDocs = this.url() + path;
      this.dropzoneOptions.url = this.urlUploadDocs
      this.dropzoneOptions.headers = this.headers();
      this.getProjects();
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-3").then(result => {
          if (result) {
            resolve(true)
            var user_id = this.userInfo.user.id
            var current_user = user_id
            var path = "/api/v1/projects"
            var city_id_copy = this.project.city_id
            this.project.city_id = this.project.city_id.id
            this.requestPost(this.url()+path, {project: this.project, current_user: current_user}).then(response => {
              var message = ""
              if(response.data.success == true){
                this.project_id_create = response.data.project.id
                var path = "api/v1/projects/multiple_uploads?project_id=" + this.project_id_create + "&current_user=" + user_id;
                this.urlUploadDocs = this.url() + path;
                this.dropzoneOptions.url = this.urlUploadDocs
                message = "Datos guardados"
              }else{
                this.percentajeForm = 100
                this.project.city_id = city_id_copy
                message = "No se pudo guardar la información"
              }
              this.$vs.notify({
                title: 'Mensaje de Globalwork',
                text: message,
                color: 'primary',
                position: 'bottom-left'
              })
            }, (err) => {
              console.log('err users -> ' +  JSON.stringify(err))
              this.percentajeForm = 100
            })
            this.errors.clear();
          } else {
            reject("correct all values");
          }
        })
      })
    },
    clearProject(){
      this.projectsExport = 0
      this.path = "/api/v1/projects?"
      this.getProjects()
      location.reload()
    },
    validateFileType(file){

      /** Reiniciar el error del dropzone cada que carguen un archivo... */
      this.dropzoneError = ''

      let files = []
      files.push(file)
      let typeAllowFiles = ["image/png","image/jpg","image/jpeg","application/pdf","application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"]
      let result = this.validateMimetypes(files,typeAllowFiles)
      if(!result){
        this.$refs.myVueDropzoneFiles.removeFile(file)
      }
    },
    setDefaultFiles () {
      this.files = this.filesTemplate
    },
    toggleAccordion(index, mustDisplay) {
      for (let i = 0; i <= 4; i++) {
        const content = document.getElementById(`content${i}`);
        const chevron = document.getElementById(`chevron${i}`);
        if (i === index) {
          if (mustDisplay) {
              chevron.textContent = 'expand_less';
              content.style.display = 'block';
          } else {
              chevron.textContent = chevron.textContent === 'expand_less' ? 'expand_more' : 'expand_less';
              content.style.display = content.style.display === 'none' ? 'block' : 'none';
          }
        } else {
          chevron.textContent = 'expand_more';
          content.style.display = 'none';
        }
      }
    },
    noFilesAdded(type_file){
      let files = this.files.filter(file => file.id === type_file )
      return files.every(file => file.content == null)
    },
    resetChevrons() {
      for (let i = 0; i <= 4; i++) {
        const chevron = document.getElementById(`chevron${i}`);
        chevron.textContent = 'expand_more';
      }
    },
    resetAccordions() {
      for (let i = 0; i <= 4; i++) {
        const content = document.getElementById(`content${i}`);
        content.style.display = 'none';
      }
    },
    disabledSubmit(){
      const filesAdded = this.files.some(file => file.content !== null)
      console.log(filesAdded)
      if (filesAdded) {
        this.submitting = false
      } else {
        this.submitting = true
      }
    }
  },
  mounted () {
    this.setDefaultFiles()
    this.resetChevrons()
    this.resetAccordions()
    this.toggleAccordion()
  },
  created: function(){
    if(this.userInfo == null ){
      window.localStorage.clear()
      this.$router.push('/pages/login')
    }else{
      this.dropzoneOptions.url = this.url() + "/api/v1/projects/import?current_user=" + this.userInfo.user.id+"&created_at_front=" + new Date().toLocaleString()
      this.getProjects();
      var cities = this.userInfo.user.cities.cities
      var cities_select = []
      cities.forEach(city => {cities_select.push({id: city.id, label: city.name})});
      this.cities_proccess = cities_select;
      this.params = "?user_id=" + this.userInfo.user.id
      this.clientId = this.userInfo.user.client_id
      this.getContractsActive()
      // Funcion para llamar la vista de descarga poligrafía
      if(this.$route.query.project_id){
        this.getProject(this.$route.query.project_id)
      }
    }
    let prev = localStorage.getItem('prev')
    if (prev != null) {
      if (parseInt(prev) == 1) {
        let currentPageTable = localStorage.getItem('currentPageTable');
        if (currentPageTable == null) {
          this.currentx = 1
        } else {
          localStorage.setItem('currentPageTable', 1);
          this.currentx = parseInt(currentPageTable)
        }
      }
    }
  },
  watch: {
    currentx:function(val) {
      localStorage.setItem('currentPageTable', val);
      this.getProjects();
    },
    popupUploadActive(val){
      if(!val){
        this.dropzoneOptionsMassive.url = ''
        this.$refs.myVueDropzoneFiles.removeAllFiles()
        this.setDefaultFiles()
        this.resetChevrons()
        this.resetAccordions()
        this.clearInputsFiles()
        this.disabledSubmit()
      }
    },
    projects_to_export(){
      if(this.projects_to_export.length == 5){
        let inputs = document.querySelectorAll('input[type=checkbox]')
        inputs.forEach(element => { if (!element.checked) {element.setAttribute('disabled','true')} })
      }else if(this.projects_to_export.length < 5){
        let inputs = document.querySelectorAll('input[type=checkbox]')
        inputs.forEach(element => { if (!element.checked) {element.removeAttribute('disabled')} })
      }
    },
    type_file(val){
      this.dropzoneOptionsMassive.url += `&type_file=${val}`
      console.log(this.dropzoneOptionsMassive.url)
    },
    'files'(){
      console.log('Change')
    }
  },
  components: {
    VueApexCharts,
    vSelect,
    FormWizard,
    TabContent,
    Datepicker,
    StarRating,
    vueDropzone: vue2Dropzone,
    ProjectComponent,
    VxTour,
    'filter-projects':FilterProjects,
    'export-massive':FilterProjectsMassive,
    'json-to-excel': JsonToExcel,
    ModalMessage,
    Loader
  }
}
// ;(function(d, w, c) {
//   w.ChatraID = 'FcAMLEj7MZgafY8hi';
//   var s = d.createElement('script');
//   w[c] = w[c] || function() {
//       (w[c].q = w[c].q || []).push(arguments);
//   };
//   s.async = true;
//   s.src = 'https://call.chatra.io/chatra.js';
//   if (d.head) d.head.appendChild(s);
// })(document, window, 'Chatra');
</script>
<style>
  .vdp-datepicker input{
    width: 100%
  }

  .cuadrov {
    padding: 10px;
    background: rgb(56, 183, 121);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 10px;
    width:50px;
    height:50px;
  }

  .cuadroa {
    padding: 10px;
    background: rgb(213, 196, 10);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 10px;
    width:50px;
    height:50px;
  }

  .cuadror {
    padding: 10px;
    background: rgb(186, 49, 49);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 10px;
    width:50px;
    height:50px;
  }

  .cuadroy {
    padding: 10px;
    /* background: rgb(150, 160, 156); */
    width:25px;
    height:25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-size: 20px;
    border-radius: 10px;
  }

  .item-concept-general{
    width: 30%;
  }

  .con-vs-slider.disabledx{
    opacity: 1 !important;
  }

  .vs-table--not-data{
    display: none !important;
  }
  .vs-table-text{
    color: #5F0090 !important;
    font-weight: bold;
  }

  .files-candiate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .list-documents {
    width: 50%;
  }
  .item-document {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 32px;
  }

  .column {
    width: 45%;
  }

  .column-2 {
    width: 45%;
    align-content: left;
  }

  .item-icon {
    vertical-align: sub;
    font-size: 15px;
  }

  .color-purple {
    color: #5F008F;
  }

  .max-upload {
    color: #3C1A4E;
    font-size: 10px;
  }

  .added-file{
    padding: 5px;
  }

  .text-size{
    font-size: 13px;
  }

  .color-green {
    color: #00AF50;
  }

  .icon-add {
    color: #5F008F;
    padding: 5px 7px !important;
    margin: 0px 5px !important;
    height: 100%;
  }

  .icon-add:hover {
    border-radius: 5px;
    height: 100%;
  }

  .vs-list--slot {
    position: relative;
    right: 200%;
  }

  .loader-create {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
    padding: 17px;
  }

  .upload-files-project {
    display: flex;
    flex-direction: column;
    margin: 7px;
  }

  .buttons-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dropzone {
      display: flex;
      justify-content: center;
  }

    .dropzone .dz-message span {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dropzone .dz-message span img {
        margin-bottom: 16px;
    }

  .dropzone__error {
      padding: 24px;
      border: 1px dashed #FE547C;
      color: #FE547C;
      background-color: #f8eaed;
      margin-top: 16px;
      max-width: 500px;
      text-align: center;
  }

  .accordion-header {
      cursor: pointer;
      border-bottom: 1px solid #ccc;
      padding: 5px;
    }

  .accordion-content {
    display: none;
    border: none;
    margin: 0 15px;
  }

  .filter-input {
    width: 100%;
  }

  .extension-error{
    color: #3C1A4E;
    margin: 7px 20px 25px;
    font-weight: 600;
  }

  .extension-message{
    text-align: center;
  }

  .no-added-files {
    font-size: 12px;
    color: #3C1A4E;
    padding: 5px;
  }

</style>
