var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            title: "Exportar Reportes Masivos",
            active: _vm.popupSearchFroProjects,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupSearchFroProjects = $event
            },
          },
        },
        [
          _c(
            "vs-row",
            [
              _c("vs-col", { attrs: { "vs-w": "6" } }, [
                _c("span", [_vm._v("Fecha de inicio")]),
                _c(
                  "div",
                  [
                    _c("datepicker", {
                      attrs: { inline: true, placeholder: "Fecha de inicio" },
                      model: {
                        value: _vm.start_date,
                        callback: function ($$v) {
                          _vm.start_date = $$v
                        },
                        expression: "start_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("vs-col", { attrs: { "vs-w": "6" } }, [
                _c("span", [_vm._v("Fecha de finalización")]),
                _c(
                  "div",
                  [
                    _c("datepicker", {
                      attrs: {
                        inline: true,
                        placeholder: "Fecha de finalización",
                      },
                      model: {
                        value: _vm.end_date,
                        callback: function ($$v) {
                          _vm.end_date = $$v
                        },
                        expression: "end_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "vs-col",
                { attrs: { "vs-w": "12" } },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-3 mb-2",
                      staticStyle: { left: "80%" },
                      on: {
                        click: function ($event) {
                          return _vm.getProjects()
                        },
                      },
                    },
                    [_vm._v("Descarga Masiva")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }