<template>
  <div>
    <vs-popup background-color="rgba(255,255,255,.6)"  class=""  title="Exportar Reportes Masivos" :active.sync="popupSearchFroProjects">      
      
      <vs-row>
        <vs-col vs-w="6">
          <span>Fecha de inicio</span>
          <div>
            <datepicker :inline="true" v-model="start_date" placeholder="Fecha de inicio"></datepicker>
          </div>
        </vs-col>
        <vs-col vs-w="6">
          <span>Fecha de finalización</span>
          <div>
            <datepicker :inline="true" v-model="end_date" placeholder="Fecha de finalización"></datepicker>
          </div>
        </vs-col>
        <vs-col vs-w="12">
          <vs-button style="left:80%;" class="mr-3 mb-2" @click="getProjects()">Descarga Masiva</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import mixinProjecs from './Projects.js'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';
import api from '../../../mixins/api'
import axios from 'axios'
export default {
  name:'FilterProjectsMassive',
  props:['popupSearchModal',],
  mixins:[mixinProjecs,api],
  computed:{
    projects:{
      get(){
        return this.$store.state.projects
      },
      set(val){
        this.$store.commit('changeProjects',val)
      }
    },
    popupSearchFroProjects:{
      get(){
        return this.popupSearchModal
      },
      set(val) {
        this.$emit('update:popupSearchModal',val)
      }
    },
    ProjectsCount:{
      get(){
        return this.projects_pagination
      },
      set(val){
        this.$emit('update:projects_pagination',val)
      }
    },
    pathToFilter:{
      get(){
        return this.path
      },
      set(val){
        this.$emit('update:path',val)
      }
    },
    projectsExportFromProject:{
      get(){
        return this.projectsExport
      },
      set(val){
        this.$emit('update:projectsExport',val)
      }
    },
    userInfo:{
      get(){
        return this.$store.state.userInfo
      }
    }
    
  },
  watch:{
  },
  data(){
    return{
      searchCity:'',
      invoiced:false,
      project:{},
      params:'',
      start_date:'',
      end_date:''
    }
  },
  components: {
    vSelect,
    Datepicker
  },
  methods:{
    change(){
      this.popupSearchFroProjects = false
    },
    async getProjects(){
      let userId = this.userInfo.user.id
      let uid = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm2, this.url()).toString(this.CryptoJS.enc.Utf8)      
      let password = this.CryptoJS.AES.decrypt(JSON.parse(localStorage.getItem('vuex')).vm3, this.url()).toString(this.CryptoJS.enc.Utf8)
      axios.post(this.urlPdfGenerator()+'api/v1/login',{email:uid,password:password},{ headers: { 'Content-Type': 'application/json' }}).then(res=>{
        if(res.data.success){
          let token = res.data.data.token
          let path = "/api/v1/projects/export_project_reports"
          path += "?id="+userId + "&starts_at="+this.start_date.toISOString().substr(0,10)+"&ends_at="+this.end_date.toISOString().substr(0,10)
          this.downloadFile(this.urlPdfGenerator()+path,{token:token},'Reportes.zip')
        }
      })

    },
    downloadFile(url, headers, filename) {
      function handleFile(data) {
        console.log(this.response || data);
        let file = URL.createObjectURL(this.response || data);
        filename = filename || url.split("/").pop();
        let a = document.createElement("a");
        if ("download" in a) {
          a.href = file;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          window.open(file)
        }
      }

      let request = new XMLHttpRequest();
      request.responseType = "blob";
      request.onload = handleFile;
      request.open("GET", url);
      for (let prop in headers) {
        request.setRequestHeader(prop, headers[prop]);
      }

      request.send();
  }

    
  }
}
</script>

<style>

</style>