
const mixinsProjects =  {
  created:function(){

  },
  data(){
    return{
      popupSearch:false
    }
  },
  methods: {
    Foo(){
      console.log('Hola')
      this.popupSearch = true
      console.log(this.popupSearch)
    }
  }
}

export default mixinsProjects
  

