/* eslint-disable no-mixed-spaces-and-tabs */


export default {
  created(){
  },
  methods:{
    /**
 * Compara y valida un objecto FileList con un array de mimetypes.
 *
 * @param  {FileList} files Lista de archivos.
 * @param  {Array}    types Array con los mimetypes a comparar.
 * @return {Boolean}        Retorna true si todos los archivos se validaron con
 *                          los mimetypes, si no, retorna undefined.
 */
    validateMimetypes(files,types){
      if (!files || files.length === 0) {
        return console.error('No se encontró ningún archivo.')
      }
      if (!types || !this.getObjectType(types).includes('Array') || types.length === 0) {
		    return console.error('No se encontró ningún tipo de archivo para comparar.')
      }

	    for (let f = 0; f < files.length; f++) {
		    let file = files[f]
		    let mimetype = file.type
		    let exists = types.includes(mimetype)
		    if (!exists) {
		    	return false
		    }
	    }

      return true
    },
    /**
 * Convierte y retorna un string con el tipo del objeto.
 *
 * @param  {Object} type Objeto que se quiere convertir
 * @return {String}      String con el tipo de objeto definido.
 */
    getObjectType(type) {
      return Object.prototype.toString.call(type)
    }
  }
}


