var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-card project" },
    [
      _c("div", { attrs: { id: "popupsProject" } }),
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            title: "Carga de archivos",
            active: _vm.popupUploadActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupUploadActive = $event
            },
          },
        },
        [
          _c("div", { staticClass: "files-candiate" }, [
            _c(
              "div",
              { staticClass: "list-documents" },
              [
                _c(
                  "vs-list",
                  [
                    _c("vs-list-header", {
                      attrs: {
                        icon: "format_list_numbered",
                        title: "Documentos necesarios para iniciar el proceso",
                      },
                    }),
                    _c(
                      "ul",
                      _vm._l(_vm.typeFiles, function (type, typeFileIndex) {
                        return _c("div", { key: type.id }, [
                          _c(
                            "div",
                            {
                              staticClass: "accordion-header",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleAccordion(
                                    typeFileIndex,
                                    false
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "item-document" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "column" },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass:
                                            "color-purple icon-add text-size",
                                          attrs: {
                                            type: "flat",
                                            disabled: _vm.allowsNewFile(
                                              type.type_file
                                            ),
                                          },
                                          on: {
                                            click: [
                                              function ($event) {
                                                $event.preventDefault()
                                                _vm.assignFile(type.type_file),
                                                  _vm.toggleAccordion(
                                                    typeFileIndex,
                                                    true
                                                  )
                                              },
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "material-icons item-icon",
                                            },
                                            [_vm._v("drive_folder_upload")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(type.label) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "column-2" }, [
                                    _c("span", { staticClass: "max-upload" }, [
                                      _vm._v(
                                        "Máximo " +
                                          _vm._s(type.maxUploads) +
                                          " " +
                                          _vm._s(
                                            type.maxUploads == 1
                                              ? "archivo"
                                              : "archivos"
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "icon-add",
                                      attrs: { type: "transparent" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "material-icons",
                                          attrs: {
                                            id: "chevron" + typeFileIndex,
                                          },
                                        },
                                        [_vm._v("expand_more")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "accordion-content",
                                  attrs: { id: "content" + typeFileIndex },
                                },
                                [
                                  _vm._l(_vm.files, function (file) {
                                    return _c("div", { key: file.input_id }, [
                                      type.type_file === file.id
                                        ? _c("span", [
                                            file.content !== null
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-document",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "material-icons item-icon color-green",
                                                        },
                                                        [_vm._v("check")]
                                                      ),
                                                      file.name_upload.length <
                                                      30
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-size color-green",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    file.name_upload
                                                                  )
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-size color-green",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    file.name_upload.substring(
                                                                      0,
                                                                      40
                                                                    ) + "..."
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                    _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "item-icon",
                                                        attrs: {
                                                          color: "danger",
                                                          type: "flat",
                                                        },
                                                        on: {
                                                          click: [
                                                            function ($event) {
                                                              $event.preventDefault()
                                                              return _vm.clearInputsFile(
                                                                file
                                                              )
                                                            },
                                                            function ($event) {
                                                              $event.stopPropagation()
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "material-icons item-icon",
                                                          },
                                                          [_vm._v("clear")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }),
                                  _vm.noFilesAdded(typeFileIndex)
                                    ? _c(
                                        "div",
                                        { staticClass: "item-document" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "no-added-files" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "material-icons item-icon",
                                                },
                                                [_vm._v("info")]
                                              ),
                                              _vm._v(
                                                " No se han agregado archivos"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.files, function (file) {
                  return _c("div", { key: file.input_id }, [
                    _c("input", {
                      ref: "inputFile" + file.input_id,
                      refInFor: true,
                      staticStyle: { display: "none" },
                      attrs: {
                        type: "file",
                        id: "inputFile" + file.input_id,
                        accept:
                          "image/png, image/jpg, image/jpeg, application/pdf",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.preChargeFiles(file.input_id)
                        },
                      },
                    }),
                  ])
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.non_valid_message,
                        expression: "this.non_valid_message",
                      },
                    ],
                    staticClass: "extension-error",
                  },
                  [
                    _c("p", { staticClass: "extension-message" }, [
                      _vm._v(
                        "Solo se permite subir formatos en png, jpg, jpeg y pdf."
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.non_valid_size,
                        expression: "this.non_valid_size",
                      },
                    ],
                    staticClass: "extension-error",
                  },
                  [
                    _c("p", { staticClass: "extension-message" }, [
                      _vm._v("El tamaño del archivo debe ser menor a 2 MB."),
                    ]),
                  ]
                ),
                this.showLoader
                  ? _c(
                      "div",
                      { staticClass: "loader-create" },
                      [_c("Loader", { attrs: { show: "true" } })],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "upload-files-project" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: {
                          color: "success",
                          type: "border",
                          disabled: this.submitting,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.uploadFilesProject()
                          },
                        },
                      },
                      [_vm._v("Subir archivos")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
        ]
      ),
      _c("filter-projects", {
        attrs: {
          popupSearchModal: _vm.popupSearch,
          projects_pagination: _vm.projects_count,
          projectsExport: _vm.projectsExport,
          path: _vm.path,
        },
        on: {
          "update:popupSearchModal": function ($event) {
            _vm.popupSearch = $event
          },
          "update:popup-search-modal": function ($event) {
            _vm.popupSearch = $event
          },
          "update:projects_pagination": function ($event) {
            _vm.projects_count = $event
          },
          "update:projectsExport": function ($event) {
            _vm.projectsExport = $event
          },
          "update:projects-export": function ($event) {
            _vm.projectsExport = $event
          },
          "update:path": function ($event) {
            _vm.path = $event
          },
        },
      }),
      _c("export-massive", {
        attrs: { popupSearchModal: _vm.popupDialogMassive },
        on: {
          "update:popupSearchModal": function ($event) {
            _vm.popupDialogMassive = $event
          },
          "update:popup-search-modal": function ($event) {
            _vm.popupDialogMassive = $event
          },
        },
      }),
      _c(
        "vs-popup",
        {
          attrs: {
            "background-color": "rgba(255,255,255,.6)",
            title: "Carga masiva",
            active: _vm.popupUploadMasiveActive,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupUploadMasiveActive = $event
            },
          },
        },
        [
          _c(
            "vs-row",
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-offset": "2",
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "8",
                  },
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/1 w-full" },
                      [
                        _c("vue-dropzone", {
                          ref: "myVueDropzoneFiles",
                          staticClass: "dropzone",
                          attrs: {
                            id: "dropzone",
                            options: _vm.dropzoneOptions,
                          },
                          on: {
                            "vdropzone-file-added": _vm.validateFileType,
                            "vdropzone-error": _vm.errorUpload,
                            "vdropzone-success": _vm.successUpload,
                          },
                        }),
                        _vm.dropzoneError !== ""
                          ? _c("div", {
                              staticClass: "dropzone__error",
                              domProps: {
                                innerHTML: _vm._s(_vm.dropzoneError),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "vx-card__collapsible-content vs-con-loading__container",
        },
        [
          _c(
            "div",
            { staticClass: "vx-card__body" },
            [
              _c(
                "div",
                { staticClass: "btn-group" },
                [
                  _vm.authorizeCreatingContract
                    ? _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "success",
                            href: _vm.downloadTemplate(),
                            target: "_blank",
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm._v(
                                "\n            Descargar plantilla excel\n            "
                              ),
                              _c("vs-icon", {
                                attrs: { icon: "cloud_download" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.authorizeCreatingContract
                    ? _c(
                        "vs-button",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.uploadMasive()
                            },
                          },
                        },
                        [
                          _vm._v("\n          Carga Masiva\n          "),
                          _c(
                            "span",
                            [
                              _c("vs-icon", {
                                attrs: { icon: "cloud_upload" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openModalSearch()
                        },
                      },
                    },
                    [
                      _vm._v("\n          Buscar\n          "),
                      _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "search" } })],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.exporMassive },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.selectIdProjects == true
                              ? "Descarga masiva de reportes"
                              : "Seleccionar Procesos"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm.selectIdProjects
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.projects_to_export.length) +
                            " / 5\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm.projectsExport > 0
                    ? _c(
                        "vs-button",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.popupExport = true
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm._v(
                                "\n            Exportar Consulta\n            "
                              ),
                              _c("vs-icon", {
                                attrs: { icon: "cloud_download" },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.projectsExport > 0
                    ? _c(
                        "vs-button",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.clearProject },
                        },
                        [
                          _c(
                            "span",
                            [
                              _vm._v(
                                "\n            Limpiar Filtro\n            "
                              ),
                              _c("vs-icon", { attrs: { icon: "clear" } }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "vs-popup",
                    {
                      staticClass: "holamundo",
                      attrs: {
                        title: "Exportar Reporte a Excel",
                        active: _vm.popupExport,
                      },
                      on: {
                        "update:active": function ($event) {
                          _vm.popupExport = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm.wait
                            ? _c(
                                "vs-progress",
                                {
                                  attrs: {
                                    indeterminate: "",
                                    color: "primary",
                                  },
                                },
                                [_vm._v("primary")]
                              )
                            : _vm._e(),
                          _c("h4", [
                            _vm._v(
                              "Cantidad de filas a exportar:" +
                                _vm._s(this.projectsExport) +
                                " "
                            ),
                          ]),
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.exportReport },
                            },
                            [_vm._v("Exportar")]
                          ),
                          _c(
                            "json-to-excel",
                            {
                              staticClass: "btn btn-default",
                              staticStyle: { display: "none" },
                              attrs: {
                                id: "exportR",
                                data: _vm.projectsToExcel,
                                fieldsExport: _vm.fieldsExcel,
                              },
                            },
                            [
                              _vm._v(" Download Data "),
                              _c("img", {
                                attrs: { src: "download_icon.png" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("br"),
              _vm.progressReport
                ? _c("vs-progress", {
                    attrs: { indeterminate: "", color: "primary" },
                  })
                : _vm._e(),
              _c(
                "vs-table",
                { attrs: { stripe: "", "max-items": "20" } },
                [
                  _c("template", { slot: "header" }, [
                    _c("h3", [
                      _vm._v("\n            Listado de procesos\n          "),
                    ]),
                  ]),
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { attrs: { "sort-key": "first_name" } }, [
                        _vm._v("\n            Número de proceso\n          "),
                      ]),
                      _c("vs-th", { attrs: { "sort-key": "last_name" } }, [
                        _vm._v("\n            Empresa\n          "),
                      ]),
                      _c("vs-th", { attrs: { "sort-key": "email" } }, [
                        _vm._v("\n            Fecha de creación\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n            Fecha de finalización\n          "
                        ),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Solicitó\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Centro de costo\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Nombre aspirante\n          "),
                      ]),
                      _c("vs-th", [_vm._v("\n            Cédula\n          ")]),
                      _c("vs-th", [
                        _vm._v("\n            Proceso\n          "),
                      ]),
                      _c("vs-th", [_vm._v("\n            Estado\n          ")]),
                      _c("vs-th", [
                        _vm._v("\n            Facturado\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Observaciones\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Calificación\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Ver reporte\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Descargar reporte\n          "),
                      ]),
                      _c("vs-th", [
                        _vm._v("\n            Subir archivos\n          "),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(_vm.projects, function (tr, indextr) {
                    return _c(
                      "vs-tr",
                      { key: indextr },
                      [
                        _c(
                          "vs-td",
                          { attrs: { data: _vm.projects[indextr].id } },
                          [
                            _vm.selectIdProjects
                              ? _c("span", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.projects_to_export,
                                        expression: "projects_to_export",
                                      },
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      name: "",
                                      id: "",
                                    },
                                    domProps: {
                                      value: _vm.projects[indextr].id,
                                      checked: Array.isArray(
                                        _vm.projects_to_export
                                      )
                                        ? _vm._i(
                                            _vm.projects_to_export,
                                            _vm.projects[indextr].id
                                          ) > -1
                                        : _vm.projects_to_export,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.projects_to_export,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = _vm.projects[indextr].id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.projects_to_export =
                                                $$a.concat([$$v]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.projects_to_export = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.projects_to_export = $$c
                                        }
                                      },
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.projects[indextr].id) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: {
                              data: _vm.projects[indextr].client_name.slice(
                                0,
                                20
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.projects[indextr].client_name.slice(0, 20)
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: { data: _vm.projects[indextr].requested_at },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.projects[indextr].requested_at) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: { data: _vm.projects[indextr].delivered_at },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.projects[indextr].delivered_at) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: {
                              data: _vm.projects[indextr].client_user_name,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.projects[indextr].client_user_name.slice(
                                    0,
                                    20
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: {
                              data: _vm.projects[indextr].billing_center,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.projects[indextr].billing_center) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: {
                              data: _vm.projects[
                                indextr
                              ].person_full_name.slice(0, 20),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.projects[indextr].person_full_name) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          { attrs: { data: _vm.projects[indextr].person_id } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.projects[indextr].person_id) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: {
                              data: _vm.projects[indextr].project_type_name,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.projects[indextr].project_type_name.slice(
                                    0,
                                    20
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.projects[indextr].polygraph_type) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: { data: _vm.projects[indextr].status_name },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.projects[indextr].status_name.slice(0, 20)
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          { attrs: { data: _vm.projects[indextr].invoiced } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.projects[indextr].invoiced) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: {
                              data: _vm.projects[indextr].payment_observation,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.projects[indextr].payment_observation
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          {
                            attrs: {
                              data: _vm.projects[indextr].overall_final_name,
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.projects[indextr].overall_final_name
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c("vs-td", [
                          _c(
                            "div",
                            { staticClass: "btn-group" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showProject(
                                        _vm.projects[indextr]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c("div", {
                                        staticClass: "point-view-project",
                                      }),
                                      _c("vs-icon", {
                                        attrs: { icon: "remove_red_eye" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("vs-td", [
                          _c(
                            "div",
                            { staticClass: "btn-group" },
                            [
                              _vm.projects[indextr].project_type_name ===
                                "Prueba de poligrafía" &&
                              _vm.projects[indextr].polygraph_attachment.slice(
                                0,
                                5
                              ) === "//s3."
                                ? _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        color: "warning",
                                        target: "_blank",
                                        href:
                                          "https:" +
                                          _vm.projects[indextr]
                                            .polygraph_attachment,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _c("vs-icon", {
                                            attrs: { icon: "cloud_download" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _c(
                                    "vs-button",
                                    {
                                      attrs: {
                                        color: "warning",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.linkToReport(
                                            _vm.projects[indextr].id,
                                            _vm.projects[indextr]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        [
                                          _c("vs-icon", {
                                            attrs: { icon: "cloud_download" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]),
                        _c("vs-td", [
                          _c(
                            "div",
                            { staticClass: "btn-group" },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: { color: "success" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.uploadDocs(
                                        _vm.projects[indextr].id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c("vs-icon", {
                                        attrs: { icon: "cloud_upload" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c("br"),
              _c("vs-pagination", {
                attrs: { total: _vm.projects_count },
                model: {
                  value: _vm.currentx,
                  callback: function ($$v) {
                    _vm.currentx = $$v
                  },
                  expression: "currentx",
                },
              }),
              _c("modal-message", {
                attrs: { modalActive: _vm.progressReport },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }